import { Component, HostListener } from '@angular/core';
import { SharedModule } from '../modules/shared/shared.module';
import { ReturnTopButtonComponent } from "../common/return-top-button/return-top-button.component";
import { SearchDossiersComponent } from "../common/search-dossiers/search-dossiers.component";
import { SharedService } from '../services/shared/shared.service';
import { RappelAbonnement } from '../pages/rappel-abonnement.component';
import { MenuBurgerComponent } from "../common/menu-burger/menu-burger.component";
import { BackdropComponent } from "../common/backdrop/backdrop.component";
import { ChangePassword } from '../pages/change-password.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-container',
    standalone: true,
    templateUrl: './app-container.component.html',
    styleUrl: './app-container.component.css',
    imports: [SharedModule, ReturnTopButtonComponent, SearchDossiersComponent, MenuBurgerComponent, BackdropComponent]
})
export class AppContainerComponent 
{
    idService!:number
    
    constructor
    (
        private sharedService: SharedService,
        private router: Router
    ) 
    {
        const id_service = this.sharedService.getIdService()
        this.idService = id_service
    }

    ngOnInit()
    {
        const change_password = JSON.parse((sessionStorage.getItem("change_password") as string)) || false

        if(change_password && this.router.url !== "/profil") 
        {
            this.needToChangePassword()
        }

        this.getDataPopup()
    }

    getDataPopup()
    {
        this.sharedService.getDataPopup(this.idService).subscribe(data => {
            if( parseInt(data[0].NB_EDIT) > 0) this.sharedService.openDialog(RappelAbonnement)
        })
    }

    needToChangePassword()
    {
        this.sharedService.openDialog(ChangePassword)
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() 
    {
        if(window.innerWidth >= 1400) 
        {
            (document.getElementById("sidebar") as HTMLElement).style.display = 'initial'
        }
        if(window.innerWidth < 1400) 
        {
            (document.getElementById("sidebar") as HTMLElement).style.display = 'none'
        }
    }

    switchToTabletMode()
    {
      return this.sharedService.switchToTabletMode()
    }
}
