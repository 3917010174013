<main class="main">
    <app-breadcrumb [title]="title"></app-breadcrumb>

    <h3 class="grey-dark h1">{{ title }}</h3>

    <span class="small bold bg-blue-light-2 blue-dark-2 p-1">Si vous souhaitez procéder à la mise à jour de vos informations, veuillez formuler votre demande <a class="blue underline cursor" (click)="goToContactPage()">via ce lien</a></span>

    <form class="profil bg-grey-light border-dark mt-4 col-6" [formGroup]="profil">
        <ul>
            @for (item of data; track $index) 
            {
                <li class="profil-item">
                    <div class="col-3">{{ item.name }}</div>
                    <div class="col-9 bolder text-break">{{ phoneNumber.includes(item.name) ? (item.value | formatPhoneNumber ) :item.value }}</div>
                </li>
            }


            @if( droits_acces.length > 0 )
            {
                <li class="profil-item">
                    <div class="col-3 align-self-start">Droits accès</div>
                    <div class="col-9 bolder">
                        <div class="d-flex">
                            <p class="col-6"><img class="access-rights-icons" [src]=" droits_acces[0].gestion_dossier ? srcIconTrue : srcIconFalse"> Gestion des dossiers</p>
                            <p class="col-6"><img class="access-rights-icons" [src]=" droits_acces[0].gestion_convention_facturation ? srcIconTrue : srcIconFalse"> Gestion convention & facturation</p>
                        </div>
                        <div class="d-flex">
                            <p class="col-6"><img class="access-rights-icons" [src]=" droits_acces[0].copie_message ? srcIconTrue : srcIconFalse"> En copie des messages</p>
                            <p class="col-6"><img class="access-rights-icons" [src]=" droits_acces[0].saisie_donnees_abonnes ? srcIconTrue : srcIconFalse"> Saisie données abonnés</p>
                        </div>
                    </div>
                </li>
            }
            
            @if (!isPasswordSectionVisible) 
            {
                <button type="button" class="bg-blue white no-border save-profil" (click)="togglePasswordSection()">Changer mon mot de passe</button>
            }

            @if (isPasswordSectionVisible)
            {
                <section>
                    <li class="profil-item password-item">
                        <div class="col-4">Saisissez votre mot de passe actuel</div>
                        <input 
                            class="col-8" 
                            type="password" 
                            formControlName="password"
                            [maxlength]="limit"
                            [ngClass]="profil.get('password')?.value.length >= limit ? 'red': ''">
                    </li>
                    <p class="red bold offset-1"><em><p>{{ errorMessage }}</p></em></p>
                    <p class="red bold offset-1" *ngIf="profil.get('password')?.value.length >= limit"><em><p>Vous avez atteint la limite du nombre de caractères autorisé</p></em></p>

                    @if( passwordCheck )
                    {
                        <li class="profil-item password-item">
                            <div class="col-4">Nouveau mot de passe</div>
                            <input 
                                class="col-8" 
                                type="password" 
                                formControlName="new_password"
                                [maxlength]="limit"
                                [ngClass]="profil.get('new_password')?.value.length >= limit ? 'red': ''">

                            <img class="password-icon" [src]="srcIconPassword" alt="montrer ou masquer le nouveau mot de passe" (click)="togglePasswordVisibility($event)">
                        </li>
                        <p class="red bold offset-1" *ngIf="isSubmitted && isInvalid('new_password') && profil.get('new_password')?.errors"><em><p>* La longueur du mot de passe doit être de 8 caractères au minimum</p></em></p>
                        <p class="red bold offset-1" *ngIf="profil.get('new_password')?.value.length >= limit"><em><p>Vous avez atteint la limite du nombre de caractères autorisé</p></em></p>
    
                        <li class="profil-item password-item">
                            <div class="col-4">Confirmez le mot de passe</div>
                            <input 
                                class="col-8" 
                                type="password" 
                                formControlName="confirm_password"
                                [maxlength]="limit"
                                [ngClass]="profil.get('confirm_password')?.value.length >= limit ? 'red': ''">

                            <img class="password-icon" [src]="srcIconPassword" alt="montrer ou masquer le nouveau mot de passe" (click)="togglePasswordVisibility($event)">
                        </li>
                        <p class="red bold offset-1" *ngIf="isSubmitted && isInvalid('confirm_password') && profil.get('confirm_password')?.errors"><em><p>* La longueur du mot de passe doit être de 8 caractères au minimum</p></em></p>
                        <p class="red bold offset-1" *ngIf="profil.get('confirm_password')?.value.length >= limit"><em><p>Vous avez atteint la limite du nombre de caractères autorisé</p></em></p>
                    }

                </section>

                @if( passwordCheck )
                {
                    <button type="button" class="bg-blue white no-border save-profil" (click)="submit()">Enregistrer le mot de passe</button>
                }
                @else
                {
                    <button type="button" class="bg-blue white no-border save-profil" (click)="checkPassword()" [disabled]="profil.get('password')?.value?.length == 0">Vérifier</button>
                }
                <p class="red bold offset-1" *ngIf="isSubmitted && profil.getError('passwordMismatch')"><em><p>* Les mots de passe ne concordent pas</p></em></p>
            }
        </ul>
    </form>

</main>
