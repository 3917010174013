<main class="main table">
    <app-breadcrumb [title]="title"></app-breadcrumb>
    
    <section class="d-flex align-items-center justify-content-between mb-3 w-100">
        <h3 class="grey-dark">{{ title }}</h3>
  
        <div class="d-flex justify-content-between align-items-center">
          <app-elements-on-table 
              (onChangeItemsPerPage)="createPagination($event);pageSize = $event;changePage(pageIndex, $event, true)" [totalResult]="totalResult">
          </app-elements-on-table>
  
          <app-searchbar (onSearch)="getAllDocuments($event)"></app-searchbar>
        </div>
      </section>
  
    <div class="mb-3">
        <span class="badge badge-pill bg-blue">N</span> Documents non consultés
    </div>
      
    @if ( !isDataReady ) 
    {
      <div class="d-flex justify-content-center">
        <mat-spinner></mat-spinner>
      </div>
    }
    @else
    { 
        <app-table [data_header_table]="data_header_table" [data_body_table]="data_body_table" [parent]="'dossiers-document'" *ngIf="data_body_table"></app-table>
    }
</main>

@if (totalPages && data_body_table && data_body_table.length > 0 ) 
{
    <app-pagination [currentPage]="pageIndex" (currentPageChange)="changePage($event)" [totalPages]="totalPages" [totalResult]="totalResult"></app-pagination>
}
