<main class="main bg-grey-light">
    <div class="main-header">
        <div class="ariane">
            <img class="picto cursor" src="/assets/img/picto-home.png" alt="">
            <div class="item small cursor"></div>
        </div>
        <h1 class="grey">Tableau de bord</h1>
    </div>


    <div class="main-content row d-flex justify-content-center">

        <div class="tile tile-01 out-of-time-folders bg-white blue orange" *ngIf="gestion_dossier || copie_message" (click)="nb_hors_delai && nb_hors_delai > 0 ? goToDossiersHorsDelai() :''"  [ngClass]="nb_hors_delai && nb_hors_delai > 0 ? 'cursor': ''">
            <div class="half left data-number">{{ nb_hors_delai }}</div>
            <div class="half right data-label">Dossier@if( nb_hors_delai && nb_hors_delai > 1 ){s} hors délai</div>
            <div class="indication grey">
                <div class="icon">
                    <div><img class="picto" src="/assets/img/picto-question.png" alt="question"></div>
                    <div class="text small w-200 bg-grey-light blue-dark-1 left p-2">
                        <img src="/assets/icons/picto-info.png" alt="" height="15">
                        Le délai pour nous retourner les pièces du dossier ou nous faire part de votre position sur l'avis du Médiateur est dépassé. Merci de nous transmettre votre retour dans les meilleurs délais
                    </div>
                </div>
            </div>
        </div>

        <div class="tile tile-02 unread-messages bg-white blue orange" (click)="nb_message_non_lus && nb_message_non_lus > 0 ? goToMessageNonLus() :''" [ngClass]="nb_message_non_lus && nb_message_non_lus > 0 ? 'cursor': ''">
            <div class="half left data-number">{{ nb_message_non_lus }}</div>
            <div class="half right data-label">Message@if( nb_message_non_lus && nb_message_non_lus > 1 ){s} non lu@if( nb_message_non_lus && nb_message_non_lus > 1 ){s}</div>
        </div>

        <div class="w-100"></div>

        <div class="tile tile-03 ongoing-folders bg-white blue cursor" *ngIf="gestion_dossier || copie_message" (click)="nb_en_cours && nb_en_cours > 0 ? goToDossiersEnCours() :''"  [ngClass]="nb_en_cours && nb_en_cours > 0 ? 'cursor': ''">
            <div class="half left data-number">{{ nb_en_cours }}</div>
            <div class="half right data-label">Dossier@if( nb_en_cours && nb_en_cours > 1 ){s} en cours</div>
        </div>

        <div class="tile tile-list tile-04 recently-opened-folders bg-white">
            <h2 class="tile-header bg-blue white">
                @if( derniers_messages.length > 1 ){Derniers dossiers consultés}
                @else{Dernier dossier consulté}
            </h2>
            <div class="tile-content">
                @for (item of derniers_messages; track item) 
                {  
                    <div class="tile-content-row blue normal"> 
                        <a class="cursor" (click)="goToMessage(item.REF, item.ID_DOSSIER)">{{ item.MESSAGE }}</a>
                    </div>
                }
            </div>
        </div>

        <div class="w-100"></div>

        <div class="tile tile-small bg-white blue" *ngIf="gestion_dossier || copie_message" (click)="nb_clos && nb_clos > 0 ? goToDossiersClos() :''"  [ngClass]="nb_clos && nb_clos > 0 ? 'cursor': ''">
            <div class="data-number">{{ nb_clos }}</div>
            <div class="data-label">Dossier@if( nb_clos && nb_clos > 1 ){s} clos</div>
        </div>

        <div class="tile tile-small bg-white blue" *ngIf="gestion_dossier || copie_message" (click)="nb_prematures && nb_prematures > 0 ? goToSaisinesPrematurees() :''"  [ngClass]="nb_prematures && nb_prematures > 0 ? 'cursor': ''">
            <div class="data-number">{{ nb_prematures }}</div>
            <div class="data-label">Saisine@if( nb_prematures && nb_prematures > 1 ){s} prématurée@if( nb_prematures && nb_prematures > 1 ){s}</div>
            <div class="indication grey">
                <div class="icon">
                    <div><img class="picto" src="/assets/img/picto-question.png" alt=""></div>
                    <div class="text small w-200 bg-grey-light blue-dark-1 left p-2">
                        <img src="/assets/icons/picto-info.png" alt="" height="15">
                        L'abonné a transmis son litige au Médiateur de l'eau mais le dossier n'est pas encore recevable à ce stade
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>