import { Component, SimpleChanges } from '@angular/core';
import { PartenariatService } from '../../../services/partenariat/partenariat.service';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from '../../../modules/shared/shared.module';
import { Document } from '../../../interfaces/Document';
import { BreadcrumbComponent } from "../../../common/breadcrumb/breadcrumb.component";

@Component({
    selector: 'app-partenariat-documents',
    standalone: true,
    templateUrl: './partenariat-documents.component.html',
    styleUrl: './partenariat-documents.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})
export class PartenariatDocumentsComponent 
{
  title:string = "Partenariat - Documents" 
  pageSize:number = 7
  pageIndex:number = 1
  idService!:number
  totalPages!:number
  totalResult!:number

  data_header_table:object = 
  {
    "badge":"",
    "date":"Date",
    "expediteur":"Expéditeur",
    "msg":"Rattaché au message",
    "etat":"État",
    "fichier":"Fichier",
    "poids":"Poids",
    "telecharger":""
  }
  data_body_table:Document[] = []
  isDataReady:boolean = false

  constructor
  (
    private partenariatService: PartenariatService,
    private sharedService: SharedService
  )
  {
    const id_service = this.sharedService.getIdService()
    this.idService = id_service
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
      this.getAllDocuments()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getAllDocuments()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getAllDocuments()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("partenariat_documents", this.idService)).subscribe(data => {
      this.totalResult = data[0]?.total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getAllDocuments(search:string="")
  {
    this.partenariatService.getAllDocuments(this.pageIndex, this.pageSize, this.idService, search).subscribe((data:Document[]) => {
      this.data_body_table = data
      this.isDataReady = true
    })
  }
}
