import { Component } from '@angular/core';
import { NavigationService } from '../services/navigation/navigation.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-message-partenaire-sans-convention',
  standalone: true,
  imports: [],
  template: `
    <div class="dialog d-flex">
    <div class="col-4 bg-blue white bold dialog-title">Suivi de partenariat</div>
    <div class="col-8 bg-white blue-dark-1 dialog-content-group">
        <div class="dialog-content">
          <p>Votre service est partenaire mais ne possède pas de suivi de partnariat.</p>
          <p>Veuillez envoyer un message à la Médiation pour commencer un suivi de partanariat</p>
            
          <div class="mt-5 float-end">
              <button 
                type="button" 
                class="bg-blue-dark-1 bold no-border px-3 py-2 me-1 mb-4 white" 
                (click)="redirectToTransmettreMessage()">
                  Commencer le suivi de partenariat
              </button>
          </div>
        </div>
    </div>
  </div>
  `
})
export class MessagePartenaireSansConventionComponent 
{

  constructor
  (
    private navigationService: NavigationService,
    private dialog: MatDialog
  ){}

  redirectToTransmettreMessage()
  {
    this.dialog.closeAll()
    this.navigationService.navigateByURL("partenariat/suivi-partenariat", [null])
  }
}
