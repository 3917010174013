import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { SuiviComponent } from '../../../pages/suivi.component';
import { DocumentComponent } from '../../../pages/documents.component';
import { TransmettreComponent } from '../../../pages/transmettre.component';
import { MessageComponent } from '../../../pages/messages.component';
import { DossiersService } from '../../../services/dossiers/dossiers.service';
import { Dossier_Data } from '../../../interfaces/Dossier_Partenariat_Data';
import { SharedService } from '../../../services/shared/shared.service';
import { BreadcrumbComponent } from "../../../common/breadcrumb/breadcrumb.component";
import { NavigationService } from '../../../services/navigation/navigation.service';

@Component({
    selector: 'app-dossier-details',
    standalone: true,
    templateUrl: './dossier-details.component.html',
    styleUrl: './dossier-details.component.css',
    imports: [SuiviComponent, DocumentComponent, TransmettreComponent, CommonModule, MessageComponent, BreadcrumbComponent]
})
export class DossiersDetailsComponent 
{
  parent:string = 'Dossier'
  title!:string
  civilite!:string
  abonne!:string
  num_contrat1!:string
  num_contrat2!:string
  adresse!:string
  cp!:string
  ville!:string
  date!:Date
  date_realisation_previ!:Date
  hors_delai!:number
  etat!:string
  liste_copie_message!:string[]
  ref!:string
  id_dossier!:number
  id_service!:number
  id_user!:number
  imgSource:string = '/assets/icons/picto-transmettreenvoi-white.png'

  parentData!:Dossier_Data
  
  gestion_dossier:boolean
  copie_message:boolean
  gestion_convention_facturation:boolean
  saisie_donnees_abonnes:boolean

  readonly tabList = Object.freeze(["suivi","documents","messages","transmettre"])

  constructor
  (
    private sharedService: SharedService,
    private dossiersService: DossiersService,
    private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService
  ) 
  {
    this.ref = this.sharedService.getParam("ref") || ""
    this.id_dossier = Number(this.sharedService.getParam("id_dossier"))
    this.id_user = this.sharedService.getIdUser()

    this.setRef()
    this.setTypeRef()
    this.setLastDossier()
    this.setLastDossierType()
    this.setLastDossierId()

    const { gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes } = this.sharedService.getUserRights()
    this.gestion_dossier = gestion_dossier
    this.copie_message = copie_message
    this.gestion_convention_facturation = gestion_convention_facturation
    this.saisie_donnees_abonnes = saisie_donnees_abonnes
    this.id_service = this.sharedService.getIdService()

    this.parentData = {ref:this.ref, id_dossier:this.id_dossier, id_service:this.id_service, type:"dossiers"}

    this.dossiersService.postTableVisiteDossier(this.id_user, this.id_dossier, this.id_service, this.ref).subscribe()
  }

  ngOnInit()
  {
    this.activatedRoute.paramMap.subscribe(params => {
      this.title = `${this.parent} ${this.ref}`
      const id_dossier = Number(this.sharedService.getParam("id_dossier"))
      this.parentData.id_dossier = id_dossier
      this.parentData.id_service = this.id_service
      this.getDetails()
    })
  }

  ngAfterViewInit() 
  {
    const onglet = this.sharedService.getParam("onglet")?.toString()

    if( onglet )
    {
      (document.querySelector(`#${onglet}`) as HTMLButtonElement).click()
    }
  }

  setRef()
  {
    sessionStorage.setItem("ref",this.ref)
  }

  setTypeRef()
  {
    sessionStorage.setItem("type_ref","dossiers")
  }

  setLastDossier()
  {
    this.sharedService.setLastItem(this.ref)
  }

  setLastDossierType()
  {
    this.sharedService.setLastItemType("dossiers")
  }

  setLastDossierId()
  {
    this.sharedService.setLastItemId(this.id_dossier)
  }

  getDetails()
  {
    this.dossiersService.getDetails(this.ref).subscribe(data => {
      this.civilite = data[0].civilite
      this.abonne = data[0].abonne
      this.num_contrat1 = data[0].num_contrat1
      this.num_contrat2 = data[0].num_contrat2
      this.adresse = data[0].adresse
      this.cp = data[0].cp
      this.ville = data[0].ville
      this.date = data[0].date
      this.date_realisation_previ = data[0].date_realisation_previ
      this.etat = data[0].etat
      this.hors_delai = data[0].hors_delai
      this.liste_copie_message = data[0].liste_copie_message
    })
  }

  changeTab(tab:string)
  {
    if(this.tabList.includes(tab))
    {
      const ref = this.sharedService.getParam("ref")?.toString()
      const id_dossier = this.sharedService.getParam("id_dossier")
      this.navigationService.navigateTo(`dossiers/details/ref_${ref}/id_dossier_${id_dossier}/onglet_${tab}`, [null])
    }
  }
}
