import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConnexionService } from '../services/connexion/connexion.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { CommonModule } from '@angular/common';
import { StorageService } from '../services/storage/storage.service';
import { ConnexionOut, contactInfo, service_eau } from '../interfaces/ConnexionOut';
import { SharedService } from '../services/shared/shared.service';
import { NewPassword } from '../pages/new-password.component';

@Component({
  selector: 'app-connexion',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './connexion.component.html',
  styleUrl: './connexion.component.css'
})
export class ConnexionComponent 
{
  isSubmit:boolean = false
  isIdentificationError:boolean = false
  isVisible:boolean = false
  rememberMe!:boolean
  form:FormGroup = new FormGroup({
    login: new FormControl("",[Validators.required, Validators.email , Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$')]),
    password: new FormControl("",[Validators.required, Validators.minLength(6)])
  })
  current_service!:number 


  constructor
  (
    public dialog: MatDialog,
    private connexionService: ConnexionService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private sharedService: SharedService
  ) 
  {
    this.current_service = Number(localStorage.getItem("current_service")) || 0
    this.rememberMe = localStorage.getItem("rememberMe") == "1" ? true : false 
    const login = localStorage.getItem("login")
    this.form.get('login')?.setValue(login)
  }

  
  toggleRememberMe()
  {
    this.rememberMe = !this.rememberMe

    if(this.rememberMe === false)
    {
      localStorage.removeItem("login")
      localStorage.removeItem("access_token")
    }
  }

  toggleVisibility()
  {
    this.isVisible = !this.isVisible
  }

  openDialog()
  {
    const dialogRef = this.dialog.open(NewPassword)

    dialogRef.afterClosed().subscribe( result => {
      console.log("dialog closed")
    })
  }

  isInvalid(controlName: string)
  {
    const control = this.form.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  submitForm()
  {
    this.isSubmit = true
    if( this.form.invalid )
    {
      this.form.markAsDirty()
      this.form.markAllAsTouched()
      console.log("invalid")
      return
    }

    const form = this.form.value

    this.connexionService.login(form.login, form.password).subscribe((data:ConnexionOut) => {

      if( !data.access_token || !data.refresh_token || !data.id_user )
      {
        console.log("Une erreur est survenue lors de la connexion")
        this.isIdentificationError = true
        return
      }

      if( this.rememberMe === true )
      {
        localStorage.setItem("rememberMe", "1")
        localStorage.setItem("login", form.login)
        localStorage.setItem("access_token", data.access_token)
      }
      
      const service_list:any = data.service_eau

      for (const property in data)
      {
        const propertyValue:string = property.toString()
        if( property == "service_eau" && data[propertyValue] instanceof Array )
        {
          (data[propertyValue] as contactInfo[]).forEach((data:contactInfo, index:number) => {
            this.storageService.set(`service${index}` , JSON.stringify(data))
          })
        }
        else
        {
          this.storageService.set(property.toString() , data[propertyValue].toString())
        }
      }

      this.isIdentificationError = false

      const token = data.access_token

      if( token && this.connexionService.isTokenExpired() == false ) 
      {        
        this.sharedService.postTableConnexion(data.id_user, 1, data.id_user).subscribe(() => {
          if(data)
          {
            if(!service_list[this.current_service].copie_message && !service_list[this.current_service].gestion_dossier)
            {
              return this.navigationService.navigateTo('partenariat',[null])
            }
            else
            {
              return this.navigationService.navigateTo('',[null])
            }
          }
        })
      }
    })
  }
}
