import { Component } from '@angular/core';
import { PartenariatService } from '../../../services/partenariat/partenariat.service';
import { SuiviComponent } from '../../../pages/suivi.component';
import { DocumentComponent } from '../../../pages/documents.component';
import { TransmettreComponent } from "../../../pages/transmettre.component";
import { CommonModule } from '@angular/common';
import { MessageComponent } from "../../../pages/messages.component";
import { DownloadService } from '../../../services/download/download.service';
import { Partenariat_Data } from '../../../interfaces/Dossier_Partenariat_Data';
import { SharedService } from '../../../services/shared/shared.service';
import { BreadcrumbComponent } from "../../../common/breadcrumb/breadcrumb.component";
import { FormatPhoneNumberPipe } from "../../../helpers/pipes/format-phone-number/format-phone-number.pipe";
import { NavigationService } from '../../../services/navigation/navigation.service';


@Component({
    selector: 'app-partenariat-details',
    standalone: true,
    templateUrl: './partenariat-details.component.html',
    styleUrl: './partenariat-details.component.css',
    imports: [SuiviComponent, DocumentComponent, TransmettreComponent, CommonModule, MessageComponent, BreadcrumbComponent, FormatPhoneNumberPipe]
})
export class PartenariatDetailsComponent 
{
  parent:string = 'Partenariat'
  service:string = this.sharedService.getService().toString()
  title!:string
  civilite!:string
  nom!:string
  prenom!:string
  telephone!:string
  email!:string
  date_dip!:string
  date_convention!:string
  ref!:string
  idUser!:number
  idService!:number
  id_dip:number
  imgSource:string = '/assets/icons/picto-transmettreenvoi-white.png'
  liste_copie_message!:string[]

  parentData!:Partenariat_Data

  gestion_dossier:boolean
  copie_message:boolean
  gestion_convention_facturation:boolean
  saisie_donnees_abonnes:boolean

  is_dip_downloadable: boolean = false
  is_convention_downloadable: boolean = false

  readonly tabList = Object.freeze(["suivi","documents","messages","transmettre"])

  constructor
  (
    private partenariatService: PartenariatService,
    private downloadService: DownloadService,
    private sharedService: SharedService,
    private navigationService: NavigationService
  ) 
  {

    this.ref = this.sharedService.getParam("ref")?.toString() || ""
    this.id_dip = Number(this.sharedService.getParam("id_dip"))

    this.setRef()
    this.setTypeRef()
    this.setLastDip()
    this.setLastDipType()
    this.setLastDipId()

    const { gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes } = this.sharedService.getUserRights()
    this.gestion_dossier = gestion_dossier
    this.copie_message = copie_message
    this.gestion_convention_facturation = gestion_convention_facturation
    this.saisie_donnees_abonnes = saisie_donnees_abonnes
    this.idService = this.sharedService.getIdService()
    this.idUser = this.sharedService.getIdUser()

    this.parentData = {ref:this.getRef(), id_dip:this.getIdDip(), id_service:this.idService, type:"partenariat"}
  }

  ngOnInit()
  {
    this.getDetails()
    this.title = `${this.ref.replaceAll("_"," ")}`
  }

  ngAfterViewInit() 
  {
    const onglet = this.sharedService.getParam("onglet")?.toString()

    if( onglet )
    {
      (document.querySelector(`#${onglet}`) as HTMLButtonElement).click()
    }
  }

  getRef()
  {
    return this.sharedService.getParam("ref")?.toString() || ""
  }

  getIdDip()
  {
    return Number(this.sharedService.getParam("id_dip"))
  }

  setRef()
  {
    sessionStorage.setItem("ref", this.getRef())
  }

  setTypeRef()
  {
    sessionStorage.setItem("type_ref","partenariat")
  }

  setLastDip()
  {
    this.sharedService.setLastItem(this.getRef())
  }

  setLastDipType()
  {
    this.sharedService.setLastItemType("partenariat")
  }

  setLastDipId()
  {
    this.sharedService.setLastItemId(this.getIdDip())
  }

  getDetails()
  {
    this.partenariatService.getDetails(this.idService, this.idUser).subscribe(data => {
      this.civilite = data[0].civilite
      this.nom = data[0].nom
      this.prenom = data[0].prenom
      this.email = data[0].email
      this.telephone = data[0].telephone
      this.date_dip = data[0].date_partenariat?.toString()
      this.date_convention = data[0].date_convention?.toString()
      this.liste_copie_message = data[0].liste_copie_message

      this.is_dip_downloadable = data[0]?.dip?.is_downloadable
      this.is_convention_downloadable = data[0]?.convention?.is_downloadable
    })
  }

  getIdService()
  {
    return this.sharedService.getIdService()
  }

  downloadDIP(fileName:string, id_user:number)
  {
    this.downloadService.downloadProcessDipConvention(this.idService, fileName, "documents/conventions", this.idUser, id_user)
  }

  changeTab(tab:string)
  {
    if(this.tabList.includes(tab))
    {
      const ref = this.sharedService.getParam("ref")
      const id_dip = this.sharedService.getParam("id_dip")
      this.navigationService.navigateTo(`partenariat/details/ref_${ref}/id_dip_${id_dip}/onglet_${tab}`, [null])
    }
  }
}
