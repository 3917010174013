import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService 
{
  private readonly HOST:string = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient,
    private sharedService: SharedService
  ) { }

  checkDownloadable(ref:string[], subFolder:string):Observable<Document[]>
  {
    const url = `${this.HOST}/check-downloadable`
    return this.httpClient.get<Document[]>(url, 
    {
      params : 
      {
        ref,
        path:subFolder
      }
    })
  }


  downloadProcess(ref:string, fileName:string, subFolder:string, idUser:number, idItem:number)
  {
    this.sharedService.blurPage()

    this.download(ref.toString(), fileName, subFolder).subscribe((blob:Blob) => this.downloadBlob(blob, fileName, idUser, idItem))
  }

  downloadProcessFacturation(ref:string, fileName:string, subFolder:string, idUser:number, idItem:number)
  {
    this.sharedService.blurPage()

    this.downloadFacturation(ref.toString(), fileName, subFolder).subscribe((blob:Blob) => this.downloadBlob(blob, fileName, idUser, idItem))
  }

  downloadProcessPartenariat(id_service:number, fileName:string, subFolder:string, idUser:number, idItem:number)
  {
    this.sharedService.blurPage()

    this.downloadPartenariat(id_service, fileName, subFolder).subscribe((blob:Blob) => this.downloadBlob(blob, fileName, idUser, idItem))
  }

  downloadProcessDipConvention(id_service:number, fileName:string, subFolder:string, idUser:number, idItem:number)
  {
    this.sharedService.blurPage()

    this.downloadDIPConvention(id_service, fileName, subFolder).subscribe((blob:Blob) => this.downloadBlob(blob, fileName, idUser, idItem))
  }

  downloadBlob(blob:Blob, fileName:string, idUser:number, idItem:number)
  {
    if( blob?.size == 0 )
    {
      alert("fichier non trouvé")
      return this.sharedService.focusPage()
    }

    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement("a")

    link.href = blobUrl
    link.download = fileName
    link.click()
    window.URL.revokeObjectURL(link.toString())

    this.sharedService.focusPage()

    this.sharedService.postTableConnexion(idUser, 434, idItem).subscribe()

  }
  
  downloadFacturation(ref:string, fileName:string, subFolder:string): Observable<Blob>
  {
    const url = `${this.HOST}/downloadFacturation`

    return this.httpClient.get(url, 
      {
        params:
        {
          ref: ref,
          fileName: fileName,
          subFolder: subFolder
        },
        responseType: 'blob'
      }
    )
  }

  downloadPartenariat(id_service:number, fileName:string, folder:string): Observable<Blob>
  {
    const url = `${this.HOST}/downloadPartenariat`

    return this.httpClient.get(url, 
      {
        params:
        {
          id_service,
          fileName,
          folder
        },
        responseType: 'blob'
      }
    )
  }

  downloadDIPConvention(id_service:number, fileName:string, folder:string): Observable<Blob>
  {
    const url = `${this.HOST}/downloadDIP`

    return this.httpClient.get(url, 
      {
        params:
        {
          id_service,
          fileName,
          folder
        },
        responseType: 'blob'
      }
    )
  }

  download(ref:string, fileName:string, subFolder:string): Observable<Blob>
  {
    const url = `${this.HOST}/download`

    return this.httpClient.get(url, 
      {
        params:
        {
          ref: ref,
          fileName: fileName,
          subFolder: subFolder
        },
        responseType: 'blob'
      }
    )
  }

  downloadAllAttachments(ref:string, path:string, list_pj:string):Observable<Blob>
  {
    const url = `${this.HOST}/download/all`

    return this.httpClient.get(url, 
      {
        params : 
        {
          ref:ref,
          path:path,
          list_pj:list_pj
        },
        responseType: 'blob'
      })
  }
}
