import { Component, SimpleChanges } from '@angular/core';
import { Dossiers } from '../../../interfaces/Dossiers';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from "../../../modules/shared/shared.module";
import { DashboardService } from '../../../services/dashboard/dashboard.service';

@Component({
  selector: 'app-dossiers-hors-delai',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './dossiers-hors-delai.component.html',
  styleUrl: './dossiers-hors-delai.component.css'
})
export class DossiersHorsDelaiComponent 
{
  title:string = "Dossiers hors délai" 
  pageSize:number = 7
  pageIndex:number = 1
  idService!:number
  totalPages!:number
  totalResult!:number
  // data_header_table = 
  // {
  //     "badge":"",
  //     "ref":"N°dossier MDE",
  //     "nom_dossier":"Nom du dossier",
  //     "num_contrat":"N°de contrat",
  //     "date":"Date saisine",
  //     "etat":"État",
  //     "hors_delai":"Hors délai"
  // }
  data_header_table = 
  {
      "badge":"",
      "ref":"N°dossier MDE",
      "nom_dossier":"Nom du dossier",
      "num_contrat":"N°de contrat",
      "date":"Date saisine",
      "etat":"État"
  }
  data_body_table: Dossiers[] = []
  isDataReady:boolean = false

  constructor
  (
    private sharedService:SharedService,
    private dashboardService: DashboardService
  ) 
  {
    const id_service = this.sharedService.getIdService()
    this.idService = id_service
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getData()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getData()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getData()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("dossiers_hors_delai", this.idService)).subscribe(data => {
      this.totalResult = data[0]?.total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getData(search:string="")
  {
    this.dashboardService.getDossiersHorsDelai(this.pageIndex, this.pageSize, this.idService, search).subscribe((data:Dossiers[]) => {
      this.data_body_table = data
      this.isDataReady = true
    })
  }
}
