<app-alert></app-alert>

<img src="/assets/icons/close.png" alt="" class="white-filter rotate-left cursor float-end m-2 img-close" (click)="displaySidebar()" *ngIf="switchToTabletMode()">

<nav class="nav nav-main h-100">
    <div class="w-100 position-relative buttons blue">
        <div id="cta-menu-open" class="cursor bg-blue">></div>
        <div id="cta-menu-close" class="cursor bg-blue"><</div>
    </div>

    <ul class="accordion accordion-flush">
        <li class="accordion-item" *ngIf="gestion_dossier || copie_message">
            <div class="nav-menu-item-group cursor pe-4 accordion-item accordion-button dashboard collapsed" data-bs-toggle="collapse" data-bs-target="#collapseDashboard" (click)="setActive($event)">
                <a class="nav-menu-item" routerLink="/" routerLinkActive="router-link-active">Tableau de bord</a>
            </div>
            <ul class="nav-menu-sub-item-group bg-blue-2 accordion-collapse collapse" id="collapseDashboard">
                <li class="nav-menu-sub-item" *ngIf="gestion_dossier || copie_message">
                    <a class="white cursor" (click)="goTo('dossiers-hors-delai')">Dossiers hors délai</a>
                    &nbsp; 
                    <span class="badge rounded-pill bg-white blue">{{ nb_hors_delai }}</span>
                </li>
                <li class="nav-menu-sub-item" *ngIf="gestion_dossier || copie_message">
                    <a class="white cursor" (click)="goTo('messages-non-lus')">Messages non lus</a>
                    &nbsp; 
                    <span class="badge rounded-pill bg-white blue">{{ nb_message_non_lus }}</span>
                </li>
                <li class="nav-menu-sub-item" *ngIf="gestion_dossier || copie_message">
                    <a class="white cursor" (click)="goTo('dossiers-en-cours')">Dossiers en cours</a>
                    &nbsp; 
                    <span class="badge rounded-pill bg-white blue text-center">{{ nb_en_cours }}</span>
                </li>
                <li class="nav-menu-sub-item" *ngIf="gestion_dossier || copie_message">
                    <a class="white cursor" (click)="goTo('dossiers-clos')">Dossiers clos</a>
                    &nbsp; 
                    <span class="badge rounded-pill bg-white blue">{{ nb_clos }}</span>
                </li>
                <li class="nav-menu-sub-item" *ngIf="gestion_dossier || copie_message">
                    <a class="white cursor" (click)="goTo('saisines-prematurees')">Saisines prématurées</a>
                    &nbsp; 
                    <span class="badge rounded-pill bg-white blue">{{ nb_prematures }}</span>
                </li>
            </ul>
        </li>
        <li class="accordion-item" *ngIf="gestion_dossier || copie_message">
            <div class="nav-menu-item-group cursor pe-4 accordion-item accordion-button dossiers collapsed" data-bs-toggle="collapse" data-bs-target="#collapseDossier" (click)="setActive($event)">
                <a class="nav-menu-item" routerLink="/dossiers" routerLinkActive="router-link-active">Dossiers</a>
            </div>
            <ul class="nav-menu-sub-item-group bg-blue-2 accordion-collapse collapse" id="collapseDossier">
                @if ( isDetailsDossierVisible ) 
                {
                    <li class="nav-menu-sub-item white bg-blue-dark-1">
                        <a class="dossiers-details">Dossier {{ ref }}</a>
                    </li>
                }
                <li class="nav-menu-sub-item"><a routerLink="/dossiers/documents" routerLinkActive="router-link-active">Documents</a></li>
                <li class="nav-menu-sub-item"><a routerLink="/dossiers/messages" routerLinkActive="router-link-active" *ngIf="copie_message">Messages</a></li>
            </ul>
        </li>
        <li class="accordion-item" *ngIf="gestion_convention_facturation">
            <div class="nav-menu-item-group cursor pe-4 accordion-item accordion-button partenariat collapsed" data-bs-toggle="collapse" data-bs-target="#collapsePartenariat" (click)="setActive($event)">
                <a class="nav-menu-item" routerLink="/partenariat" routerLinkActive="router-link-active">Partenariat</a>
            </div>
            <ul class="nav-menu-sub-item-group bg-blue-2 accordion-collapse collapse" id="collapsePartenariat">
                @if ( isDetailsPartenariatVisible ) 
                {
                    <li class="nav-menu-sub-item white bg-blue-dark-1">
                        <a class="partenariat-details">{{ ref?.replaceAll("_", " ") }}</a>
                    </li>
                }
                <li class="nav-menu-sub-item" [ngClass]="{'offset': isDetailsPartenariatVisible }">
                    <a routerLink="/partenariat/documents" routerLinkActive="router-link-active">Documents</a>
                    <span class="badge rounded-pill bg-orange white">{{ nb_document_dip }}</span>
                </li>
                <li class="nav-menu-sub-item" [ngClass]="{'offset': isDetailsPartenariatVisible }" *ngIf="copie_message">
                    <a routerLink="/partenariat/messages" routerLinkActive="router-link-active">Messages</a>
                    <span class="badge rounded-pill bg-orange white">{{ nb_message_dip }}</span>
                </li>
            </ul>
        </li>
        <li class="accordion-item">
            <div class="nav-menu-item-group cursor interlocuteurs" (click)="setActive($event)">
                <a class="nav-menu-item" routerLink="/interlocuteurs" routerLinkActive="router-link-active">Interlocuteurs</a>
            </div>
        </li>
        <li class="accordion-item" *ngIf="gestion_convention_facturation || saisie_donnees_abonnes">
            <div class="nav-menu-item-group cursor donnees-abonnes" (click)="setActive($event)">
                <a class="nav-menu-item" routerLink="/donnees-abonnes" routerLinkActive="router-link-active">
                    Données abonnés
                    &nbsp;
                    <img class="picto" src="/assets/icons/picto-warning-orange.png" alt="" *ngIf="nb_need_edit && nb_need_edit > 0">
                </a>
            </div>
        </li>
        <li class="accordion-item" *ngIf="gestion_convention_facturation">
            <div class="nav-menu-item-group cursor facturation" (click)="setActive($event)">
                <a class="nav-menu-item" routerLink="/facturation" routerLinkActive="router-link-active">Facturation</a>
            </div>
        </li>
        <li class="accordion-item">
            <div class="nav-menu-item-group cursor contact">
                <a class="white" (click)="goToContactPage()">Nous contacter</a>
            </div>
        </li>
    </ul>
</nav>
<div class="guide cursor">
    <img src="assets/icons/manuel.png" alt="Guide d'utilisation de l'espace partenaire">
    <a class="disabled" href="assets/files/MDE_Guide_Partenaires.PDF">Guide d'utilisation de l'espace partenaire</a>
</div>
