import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { contactInfo } from '../../interfaces/ConnexionOut';
import { SharedService } from '../shared/shared.service';
import { MessagePartenaireSansConventionComponent } from '../../pages/message-partenaire-sans-convention.component';

@Injectable({
  providedIn: 'root'
})
export class PartenariatGuardService 
{
  constructor(
    private router:Router,
    private sharedService: SharedService
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {  
    const service:contactInfo = JSON.parse(String(sessionStorage.getItem(`service${this.sharedService.getCurrentService()}`)))

    if( service?.id_state_dip?.includes(27) && service.id_state_dip.length > 0 ) return true // id state dip 27 : Suivi Partenariat
    else 
    {
      this.sharedService.openDialog(MessagePartenaireSansConventionComponent)
      return this.router.parseUrl('/partenariat')  
    }
  }
}

export const PartenariatGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  => 
{
  return inject(PartenariatGuardService).canActivate(next, state)
}
