import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedModule } from '../modules/shared/shared.module';
import { DossiersService } from '../services/dossiers/dossiers.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Dossier_Data, Partenariat_Data } from '../interfaces/Dossier_Partenariat_Data';
import { SharedService } from '../services/shared/shared.service';
import { UploadService } from '../services/upload/upload.service';
import { PartenariatService } from '../services/partenariat/partenariat.service';
import { SuiviPartenariatService } from '../services/suivi-partenariat/suivi-partenariat.service';
import { MessageOut, MessageOutPartenariat, MessageOutSuiviPartenariat } from '../interfaces/MessageOut';
import { Router } from '@angular/router';

@Component({
  selector: 'app-transmettre',
  standalone: true,
  imports: [SharedModule],
  template: `
    <form class="transmettre-form" [formGroup]="form">
        <h5>Transmettre Message / Document(s)</h5>
        <div class="d-flex">
            <div class="col-6 pe-5">

                <div class="form-check form-check-inline my-1">
                    <input class="form-check-input form-control cursor checkBoxMessages" type="checkbox" #checkBoxMessages (click)="toggleVisiblity('message')">
                    <label class="form-check-label bold cursor" (click)="checkBoxMessages.click()">Je souhaite transmettre un message</label>
                </div>

                <div *ngIf="sendMessage">
                    <div class="my-2">
                        <div>Objet</div>
                        <input 
                            type="text" 
                            formControlName="objet" 
                            class="form-control" 
                            (input)="isButtonDisabled()" 
                            [maxLength]="inputLimit.get('objet')"
                            [ngClass]="form.get('objet')?.value.length >= inputLimit.get('objet') ? 'red': ''">
                        <p>
                            <small class="float-start grey">255 caractères maximum</small>
                            <span class="float-end" [ngClass]="form.get('objet')?.value.length >= inputLimit.get('objet') ? 'red': 'blue'">
                                {{ form.get('objet')?.value.length }}
                                {{ form.get('objet')?.value.length > 1 ? ' caractères': ' caractère' }}
                            </span>
                        </p>
                    </div>

                    <div class="mt-4 mb-2">
                        <div>Votre message</div>
                        <textarea class="form-control input-message" formControlName="message" (input)="isButtonDisabled()"></textarea>
                    </div>

                    <div class="form-check form-check-inline mt-2" *ngIf="!suivi_partenariat">
                        <input class="form-check-input form-control cursor checkBoxDestinataires" type="checkbox" #checkBoxDestinataires (click)="toggleVisiblity('destinataire')">
                        <label class="form-check-label bold cursor" (click)="checkBoxDestinataires.click()">Transmettre ce message à d'autres destinataires par email</label>
                    </div>


                    <div *ngIf="addDestinataires && !suivi_partenariat">
                        <p class="d-block small">Veuillez entrer l'adresse mail des destinataires 
                            <br> (séparé par une virgule ou un point-virgule, si il y en a plusieurs)
                        </p>
                        <p class="small red"> <img src="/assets/icons/picto-warning-orange.png" height="10"> Nombre limite de destinataires en copie : {{ inputLimit.get('nb_destinataire') }}</p>
                        <div>
                            <p *ngIf="liste_destinataires.length > 0">Liste des destinataires: </p>
                            @for (item of liste_destinataires; track $index) 
                            {
                                <p class="d-flex justify-content-between">{{$index+1}}. {{item}} <img class="cursor" src="/assets/icons/picto-croix-rouge.png" alt="retirer destinataire" height="10" (click)="removeDestinataire($index)"> </p>
                            }
                        </div>

                        <div class="input-group mb-3">
                            <textarea class="form-control px-2 pt-1" rows="6" placeholder="exemple@gmail.fr" value="{{ liste_copie_message.toString() }}" #input></textarea>
                        </div>
                        <button class="bg-blue white no-border px-3 py-2 d-flex align-self-end" type="button" (click)="addToDestinatairesList(input.value);input.value = ''" matTooltip="Seuls les {{inputLimit.get('nb_destinataire')}} premiers destinataires de la liste seront pris en compte">Ajouter</button>
                    </div>
                </div>
            </div>

            <div class="col-6 px-4">
                <div class="form-check form-check-inline my-1">
                    <input class="form-check-input form-control cursor checkBoxDocuments" type="checkbox" #checkBoxDocuments (click)="toggleVisiblity('document')">
                    <label class="form-check-label bold cursor" (click)="checkBoxDocuments.click()">Je souhaite transmettre / joindre un ou plusieurs documents</label>
                </div>

                <div class="my-2 blue bold" *ngIf="documentList.length > 0">
                    Nombre de document@if(documentList.length > 1){s} à transmettre : {{ documentList.length }} 
                </div>

                <div *ngIf="sendDocument">

                    <!-- Ne pas supprimer, cette zone de texte pourra être utilisée pour donner des informations aux utilisateurs de la part de la MDE -->
                    <!-- <small class="d-block small">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Blanditiis repellendus ullam magni?</small> -->

                    <div class="liste-documents ps-3 pe-5 py-2" [ngClass]="documentList.length > 0 ? '' : 'd-none'">
                        @for (item of documentList; track $index) 
                        {
                            <div class="d-flex col-12 justify-content-between">
                                {{ item.name }}
                                <img class="align-self-end cursor" src="/assets/icons/picto-croix-rouge.png" height="10" alt="" (click)="removeDocument($index)">
                            </div>
                            <hr>
                        }
                    </div>
    
                    <div class="drag-and-drop-zone mt-3 mb-3 position-relative" (click)="fileUpload.click()">
                        <div class="drag-and-drop-text">Glissez-déposez vos fichiers dans cette zone</div>
                        <input class="opacity-0 z-3 h-100 w-100 bg-danger position-absolute cursor" type="file" #fileUpload multiple accept="{{allowedTypes}}" (change)="onChangeFile($event)">
                    </div>
    
                    <div class="input-group">
                        <input type="text" class="form-control p-2" [value]="fileName" readonly>
                        <button class="btn white bg-blue-dark-1" type="button" (click)="fileUpload.click()">Parcourir</button>
    
                        <button class="ms-2 white btn bg-blue-dark-1 rounded-0" type="button" (click)="addToDocumentList()">Ajouter le fichier</button>
                    </div>
                </div>

            </div>
        </div>
        <div class="d-flex justify-content-center mt-4">
            <button type="button" class="transmettre-button cursor" 
                (click)="transmettreMessage()" 
                [ngClass]="isDisabled ? 'bg-grey grey-dark not-allowed': 'bg-blue white cursor'"
                [disabled]="isDisabled">
            Transmettre
            </button>
        </div>
    </form>
  `,
  styles: 
  `
    .form-control,
    .liste-documents,
    .drag-and-drop-zone
    {
        border: 2px solid #BCBCBC;
    }

    .transmettre-form
    {
        border: 1px solid #BCBCBC;
        padding: 1rem;
        color: #828282;
    }

    .input-message
    {
        height: 11rem;
    }

    .input-destinataires
    {
        margin-top: 0.3rem;
        height: 4rem;
    }

    .form-control
    {
        padding: 0;
        border-radius: 0;
    }

    .liste-documents
    {
        height: 9rem;
        overflow-y: scroll;
        scrollbar-color: #292929 white;
        scrollbar-width: thin;
    }

    .drag-and-drop-zone
    {
        height: 7rem;
        padding: 1.5rem 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .drag-and-drop-text
    {
        border: 2px dashed #BCBCBC;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .transmettre-button
    {
        padding: 0.7rem 1.7rem;
        border: 0;
    }
  `
})

export class TransmettreComponent 
{
  date:Date = new Date()
  formatedDate = this.date.toLocaleDateString().split("/").join('-')
  @Input() data!:Dossier_Data|Partenariat_Data
  @Input() ref!:string
  @Input() liste_copie_message!:string[]
  @Input() id_dossier!:number

  fileName:string = ""
  documentList:File[] = []
  multipleDocument:File[] = []
  liste_destinataires:string[] = []
  sendMessage:boolean = false
  sendDocument:boolean = false
  addDestinataires:boolean = false
  form!: FormGroup
  idUser!:number
  idService!:number
  isDisabled:boolean = true
  isSubmitted:boolean = false
  list_documents_id:{ID:number}[] = []
  suivi_partenariat:boolean = this.isSuiviPartenariat() || false
  readonly allowedTypes:string[] = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'image/png', 'image/jpeg', 'image/jpg', 'zip', 'application/octet-stream', 'application/zip', 'application/x-zip', 'application/x-zip-compressed']
  readonly maxSize = 30 * 1024 * 1024
  readonly maxTotalSize = 100 * 1024 * 1024
  readonly inputLimit = 
  Object.freeze(
    new Map()
    .set("objet", 255)
    .set("nb_destinataire", 15)
  )

  constructor
  (
    private dossiersService: DossiersService,
    private partenariatService: PartenariatService,
    private suiviPartenariatService: SuiviPartenariatService,
    private snackBar: MatSnackBar,
    private sharedService: SharedService,
    private uploadService: UploadService,
    private router: Router
  )
  {
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user

    const id_service = this.sharedService.getIdService()
    this.idService = id_service

    this.form = new FormGroup({
        objet: new FormControl(""),
        message: new FormControl(""),
        liste_destinataires: new FormControl(this.liste_destinataires),
        liste_pj: new FormControl(this.documentList)
    })
  }

  onChangeFile(event:Event)
  {
    this.fileName = ""
    this.multipleDocument = []
    const errorType:string[] = []
    const errorSize:string[] = []
    const errorTotalSize:string[] = []
    const inputElement = event.target as HTMLInputElement
    
    if( inputElement.files && inputElement.files.length > 0)
    { 
        const totalSize = Array.from(inputElement.files).reduce((accumulator, file) => {
            return accumulator + file.size
          }, 0)

        if( totalSize >= this.maxTotalSize )
        {
            const fileList = Array.from(inputElement.files).map(file => {
                return `fichier:${file.name}, taille:${(file.size / 1024 / 1024).toFixed(2)} Mo \n`
            })

            fileList.map(file => errorTotalSize.push(file))
        }

        if(errorTotalSize.length > 0)
        {
            alert(`La somme de la taille des fichiers suivants excède 100 Mo.\n Veuillez réduire le nombre de documents ou utiliser un format de fichier plus compressé : \n ${errorTotalSize.toString().replaceAll(",","\n")}`)
            return
        }

        const arrayFile = Array.from(inputElement.files)

        arrayFile.forEach(element => {
            if(!this.allowedTypes.includes(element.type))
            {
                errorType.push(element.name)
                return
            }

            if(element.size > this.maxSize)
            {
                errorSize.push(element.name)
                return
            }

            this.fileName += ` "${element.name}" `
            this.multipleDocument.push(element)
        })

        if( errorType.length !== 0) alert(`Les fichiers : \n ${errorType.toString().replaceAll(",","\n")} \n ne seront pas pris en compte car ils ne correspondent pas aux types de fichiers autorisés`)
        if( errorSize.length !== 0) alert(`Les fichiers ci-dessous : \n ${errorSize.toString().replaceAll(",","\n")} \n ne seront pas transmis car ils possèdent une taille supérieure à 30 Mo`)
    }
  }

  addToDocumentList()
  {
    if(this.fileName == "") return

    this.multipleDocument.forEach(document => {
        if(!this.documentList.includes(document)) this.documentList.push(document)  
    })

    this.fileName = ""
    this.isButtonDisabled()
  }

  removeDocument(index:number)
  {
    if (index > -1) 
    { 
        this.documentList.splice(index, 1)
    }
    this.isButtonDisabled()
  }

  addToDestinatairesList(value:string)
  {
    if( value == "" ) return

    value.split(/[;,\n\r]/).every((e,index) => {
        if(index > (this.inputLimit.get('nb_destinataire') - 1)) return false 
        e = e.trim()
        if( e.length == 0  || e == "" || !this.isValidEmail(e) ) return
        this.liste_destinataires.push(e)
        return true
    })

    this.liste_destinataires = this.liste_destinataires.slice(0, this.inputLimit.get('nb_destinataire'))
  }

  removeDestinataire(index:number)
  {
    if (index > -1) 
    { 
        this.liste_destinataires.splice(index, 1)
    }
  }

  toggleVisiblity(type:string)
  {
    if(type === "message")
    {
        this.sendMessage = !this.sendMessage
    } 
    else if(type === "document") 
    {
        this.sendDocument = !this.sendDocument
        if(this.sendDocument == false) this.documentList = []
    }
    else if(type === "destinataire") 
    {
        this.addDestinataires = !this.addDestinataires

        if(this.addDestinataires == false) this.liste_destinataires = []
    }
    this.isButtonDisabled()
  }
  
  isButtonDisabled()
  {
    const form = this.form.value

    if( 
        (!this.sendMessage && !this.sendDocument)
        || (this.sendMessage && (form.objet.length === 0 || form.message.length === 0))
        || (this.sendDocument && this.documentList.length === 0)
    )
    {
        this.isDisabled = true
    }
    else  this.isDisabled = false
  }

  isValidEmail(email:string)
  {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailRegex.test(email)
  }

  isSuiviPartenariat()
  {
    const currentURL = this.router.url.split("/").slice(-1)[0]
    return currentURL.includes("suivi-partenariat") ? true : false
  }

  resetForm()
  {
    this.form.get("objet")?.setValue("")
    this.form.get("message")?.setValue("")
    this.form.get("list_pj")?.setValue([])

    this.isDisabled = true
    if(this.sendMessage) (document.querySelector(".checkBoxMessages") as HTMLInputElement).click()
    if(this.sendDocument) (document.querySelector(".checkBoxDocuments") as HTMLInputElement).click()
    if(this.addDestinataires) (document.querySelector(".checkBoxDestinataires") as HTMLInputElement).click()

    this.isButtonDisabled()
  }


  getPayloadDossier(form: MessageOut, list_documents_id: {ID:number}[], ref:string)
  {
    return {
        objet: form.objet.slice(0, 255),
        message: form.message,
        liste_destinataires: form?.liste_destinataires.slice(0, this.inputLimit.get('nb_destinataire')),
        liste_pj: list_documents_id,
        ref: ref,
        idUser: this.idUser,
        idService: this.idService
    }
  }

  getPayloadPartenariat(form: MessageOutPartenariat, list_documents_id: {ID:number}[], id_dip: number)
  {
    return {
        objet: form.objet.slice(0, 255),
        message: form.message,
        liste_destinataires: form?.liste_destinataires.slice(0, this.inputLimit.get('nb_destinataire')),
        liste_pj: list_documents_id,
        idUser: this.idUser,
        idService: this.idService,
        idDip: id_dip
    }
  }

  getPayloadSuiviPartenariat(form: MessageOutSuiviPartenariat, list_documents_id: {ID:number}[])
  {
    return {
        objet: form.objet.slice(0, 255),
        message: form.message,
        liste_pj: list_documents_id,
        idUser: this.idUser,
        idService: this.idService
    }
  }


  async transmettreMessage()
  {
    this.form.get("liste_pj")?.setValue(this.documentList)

    const form = this.form.value
    
    if( form.objet.length === 0 && form.message.length === 0 && form?.liste_destinataires?.length === 0 && form?.liste_pj?.length === 0 ) return

    const is_suivi_partenariat = this.suivi_partenariat

    const formData = this.appendIdToFormData()

    const array_files:File[] = []

    array_files.push(form.liste_pj)

    array_files.flat().forEach(element => {
        formData.append("files", element)
    })

    this.isDisabled = false
    this.sharedService.blurPage()

    if( !this.data && is_suivi_partenariat )
    {
        const folder = "services"

        return this.postMessageSuiviPartenariat(formData, form, folder)
    }
    else if( this.data && this.data.type == "dossiers" && !is_suivi_partenariat)
    {
        const folder = "dossiers"

        this.postMessageDossier(formData, form, folder)
    }
    else if( this.data && this.data.type == "partenariat" && !is_suivi_partenariat)
    {
        const folder = "services"

        this.postMessagePartenariat(formData, form, folder)     
    }
    else return
  }

  async postMessageDossier(formData:FormData, form:MessageOut, folder:string)
  {
    const id_dossier = this.id_dossier ? this.id_dossier : Number(this.sharedService.getParam('id_dossier'))

    formData.append("subFolder", this.ref.toString())
    formData.append("folder", folder)
    formData.append("id_dossier", id_dossier.toString());

    ((await this.uploadService.uploadFile(formData)).subscribe((list_documents_id) => {
        this.dossiersService.postMessage(this.getPayloadDossier(form, list_documents_id, this.ref)).subscribe({
            next: (data: any) => 
            {
                if(data)
                { 
                    this.afterPostMessage()
                }
            },
            error: (e:Error) => 
            {
                this.errorSnackbar(e, "dossier")
            }
        })
    }))
  }


  async postMessagePartenariat(formData:FormData, form:MessageOutPartenariat, folder:string)
  {
    if(this.data.type == "partenariat" && this.sharedService.isPartenariatData(this.data))
    {
        const id_dip : number = Number(this.data.id_dip)
    
        formData.append("id_dip", id_dip.toString())
        formData.append("subFolder", this.idService.toString())
        formData.append("folder", folder);
    
        ((await this.uploadService.uploadFile(formData)).subscribe((list_documents_id) => {
            this.partenariatService.postMessage(this.getPayloadPartenariat(form, list_documents_id, id_dip)).subscribe({
                next: (data: any) => 
                {
                    if(data)
                    { 
                        this.afterPostMessage()
                    }
                },
                error: (e:Error) => 
                {
                    this.errorSnackbar(e, "partenariat")
                }
            })
        }))
    }
  }


  async postMessageSuiviPartenariat(formData:FormData, form:MessageOutSuiviPartenariat, folder:string)
  {
    formData.append("subFolder", this.idService.toString())
    formData.append("folder", folder);

    ((await this.uploadService.uploadFile(formData)).subscribe((list_documents_id) => {
        this.suiviPartenariatService.postMessage(this.getPayloadSuiviPartenariat(form, list_documents_id)).subscribe({
            next: (data: any) => 
            {
                if(data)
                { 
                    this.afterPostMessage()
                }
            },
            error: (e:Error) => 
            {
                this.errorSnackbar(e, "suivi partenariat")
            }
        })
    }))
  }

  appendIdToFormData()
  {
    const formData = new FormData()

    formData.append("id_user", this.idUser.toString())
    formData.append("id_service", this.idService.toString())

    return formData
  }

  afterPostMessage()
  {
    this.resetForm()
                    
    this.snackBar.open("Votre demande est transmise", "Ok", {
        duration: 5000,
    })
            
    this.sharedService.postTableConnexion(this.idUser, 499, this.idUser).subscribe()
            
    this.sharedService.focusPage()
    this.isDisabled = false
  }
  
  errorSnackbar(e:Error, service:string)
  {
    console.log({e, service:`post message ${service}`})
    
    this.snackBar.open(`Une erreur est survenue lors de la transmission de votre message`, "J'ai compris", {
        duration: 5000,
    })
  }
}
