<div class="mat-elevation-z0">
    <table mat-table [dataSource]="dataSource" matSort>
        @for (item of displayedColumns; track $index) 
        {
            <ng-container matColumnDef="{{item}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span>{{titleColumns[$index]}}</span>
                </th>

                <!--! Changer ngClass pour les dossiers hors délai -->
                <td 
                    mat-cell *matCellDef="let element; let i = $index" 
                    class="text-center text-break"
                    [ngClass]="
                    [
                        item == 'badge' ? '' : '{{item}}',
                        element.hors_delai < 0 ? 'orange' : '', 
                        element.non_lu ? 'blue bold' : '',
                    ]"
                    (click)=" 
                    arrayDetailDossierList.includes(parent) && !exclusion.includes(item) ? goToDossierDetails(element.ref, parent, element.id_dossier) : '';
                    arrayDetailPartenariatList.includes(parent)  && !exclusion.includes(item) ? goToPartenariatDetails(element.ref_dip, parent, element.id_dip) : ''
                    "
                    >
                    @if (item == "badge") 
                    {
                        <span class="badge badge-pill bg-blue" *ngIf="element.non_lu == 1">N</span>
                    }
                    @else if (item == "warning") 
                    {
                        <img src="/assets/icons/picto-warning-orange.png" height="15" alt="" *ngIf="element.hors_delai">
                    }
                    @else if (item == "telecharger" && parent == 'facturation') 
                    {
                        <img class="table-icons cursor" src='/assets/icons/picto-telecharger.png' *ngIf="element.num_facture && element.is_downloadable" (click)="download_facturation(element.num_facture, element.num_facture.concat('.pdf'), element.annee, idService)">
                    }
                    @else if (item == "telecharger" && parent == "dossiers-document") 
                    {
                        <img class="table-icons cursor" src='/assets/icons/picto-telecharger.png' *ngIf="element.ref && element.is_downloadable" (click)="download_dossier_document(element.ref, element.url_doc); markAsRead(element.id_document, idUser)">
                    }
                    @else if (item == "telecharger" && parent == "partenariat-document") 
                    {
                        <img class="table-icons cursor" src='/assets/icons/picto-telecharger.png' *ngIf="element.is_downloadable" (click)="download_partenariat_document(element.url_doc); markDocPartenariatAsRead(element.id_document, idUser)">       
                    }
                    @else if (item == "exporter") 
                    {
                        <!-- <img class="table-icons cursor" src='/assets/icons/picto-export-csv.png' *ngIf="element.is_downloadable && (element.ref || element.num_facture) && (parent == 'facturation' || element.poids)" (click)="export(element.ref || element.num_facture)"> -->
                    }
                    @else if (item == "montant_ht") 
                    {
                        {{element.montant_ht.toFixed(2) | formatNumber }} {{ devise }}
                    }
                    @else if (item == "poids" && element.poids) 
                    {
                        {{ element.poids | convertSize }}
                    }
                    @else if (item == "ref") 
                    {
                        {{element.ref}} 
                    }
                    @else if (item == "num_contrat") 
                    {
                        @if(element.num_contrat1){ contrat @if(element.num_contrat2 && (element.num_contrat1 != element.num_contrat2)){1} : {{element.num_contrat1}} }
                        @if(element.num_contrat2 && (element.num_contrat1 != element.num_contrat2)){ <br> contrat 2 : {{element.num_contrat2}} }
                    }
                    @else if (item.includes('date'))
                    {
                        @if(item == 'date_heure')
                        {
                            {{ element[item] ? element[item].split(" ")[0] : '-' }} <!-- dd-MM-yyyy -->
                            {{ element[item] ? element[item].split(" ")[1] : '-' }} <!-- HH:mm:ss -->
                        }
                        @else
                        {
                            {{ element[item] ? (element[item] | date:'dd-MM-yyyy') : '-' }}
                        }
                    }
                    @else if (item == "msg_non_lus" || item == "doc_non_lus")
                    {
                        {{ element[item] ? element[item] : 0 }}
                    }
                    @else if (item == "documents")
                    {
                        {{ element.list_pj ? element?.list_pj?.split(",")?.length : 0 }}
                    }
                    @else 
                    {
                        {{ element[item] != null ? element[item] : "-" }}
                    }
                </td>
            </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table"></tr>
        
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="content-table cursor" [ngClass]="{'cursor': detailList.includes(parent)}"></tr>
        
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                <h3 class="orange d-flex justify-content-center mt-5">Aucun résultat pour cette recherche</h3>
            </td>
        </tr>
    </table>
</div>
