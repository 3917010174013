import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StorageService } from '../../services/storage/storage.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-elements-on-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './elements-on-table.component.html',
  styleUrl: './elements-on-table.component.css'
})
export class ElementsOnTableComponent 
{
  constructor(
    private storageService: StorageService
  ) {}

  @Input() totalResult!:number
  @Output() onChangeItemsPerPage: EventEmitter<number> = new EventEmitter<number>()
  selectValue:number = 0
  itemsOnPages: number [] = [7,15,30,50]

  changeItemsPerPage(event: Event)
  {
    if( event.target instanceof HTMLSelectElement )
    {
      const index:number = event.target.selectedIndex
      this.storageService.set("nb_liste_actions", this.itemsOnPages[index].toString())
      this.selectValue = index
      this.onChangeItemsPerPage.emit(this.itemsOnPages[this.selectValue])
      this.setPageSize(this.itemsOnPages[this.selectValue])
    }
  }

  setPageSize(value:number)
  {
    return this.storageService.get("nb_documents")
      ? Number(this.storageService.get("nb_documents"))
      : value
  }
}
