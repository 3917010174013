<div class="carousel-wrapper" *ngIf="isCarouselContainerVisible">
    @if (isCarouselVisible)
    {
        <div id="carousel" class="carousel" [ngClass]="bgColor">
            <div class="carousel-inner position-relative">
                <div class="grey close-icon cursor d-block float-end" (click)="toggleAlert()">
                    <img class="white-filter" src="/assets/icons/close.png" height="20" alt="fermer">
                </div>

                @for (item of slides; track $index) 
                {        
                    <div class="carousel-item"
                        [ngClass]="
                        [
                            item.type_alerte == 'alerte' ? 'alerte' : 'warning',
                            $index === 0 ? 'active': '' 
                        ]">
                        <div class="d-flex">
                            <img class="me-3" src="/assets/icons/danger-white.png" alt="icône avertissement" height="35">

                            <div class="content" [ngClass]="item.contenu.length >= 80 ? 'scroll-text': ''">
                                {{item.contenu}}
                            
                                <button 
                                    class="btn-action mt-2" 
                                    type="button"
                                    [ngClass]="textColor"
                                    (click)="toggleAlert()"
                                    matTooltipPosition="right">
                                    Fermer
                                </button>
                            </div>

                        </div>
                    </div>
                }
            </div>

            <div class="d-flex justify-content-center align-self-end">
                <div class="carousel-indicators">
                    <button *ngFor="let item of slides; let i = index" 
                        type="button" 
                        [attr.data-bs-target]="'#carousel'" 
                        [attr.data-bs-slide-to]="i" 
                        [class.active]="i === 0" 
                        [attr.aria-current]="i === 0 ? 'true' : null" 
                        [attr.aria-label]="'Slide ' + (i + 1)">
                    </button>
                  </div>
            </div>

            <button class="carousel-control-prev" type="button" data-bs-target="#carousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <img class="carousel-navigation-icons rotate-left" src="/assets/icons/triangle.png" height="10" alt="précédent">
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carousel" data-bs-slide="next">
              <img class="carousel-navigation-icons rotate-right" src="/assets/icons/triangle.png" height="10" alt="suivant">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
        </div>
    }
    @else
    {
        <div class="alert-collapsed cursor" 
            (click)="toggleAlert()"
            [ngClass]="
            [
                nb_alertes > 0 ? 'bg-red' : '',
                nb_alertes == 0 && nb_informations > 0 ? 'bg-orange' : ''
            ]"
            >
            <div class="d-flex align-items-center">
                <img class="alert-collapsed-icon" src="/assets/icons/danger-white.png" alt="icône alerte">
                <small class="extra-small">
                    {{ nb_alertes }} alerte@if(nb_alertes > 1){s},
                    {{ nb_informations }} information@if(nb_alertes > 1){s} 
                </small>
            </div>
            <div class="select-alert">
                <small class="extra-small">Voir les alertes</small>
                <img class="rotate-right white-filter" src="/assets/icons/triangle.png" alt="détails alerte" height="10">
            </div>
        </div>
    }
</div>


