import { Component, SimpleChanges } from '@angular/core';
import { ListeActionsService } from '../../services/liste-actions/liste-actions.service';
import { SharedService } from '../../services/shared/shared.service';
import { SharedModule } from '../../modules/shared/shared.module';
import { ListeActions } from '../../interfaces/ListeActions';
import { BreadcrumbComponent } from "../../common/breadcrumb/breadcrumb.component";

@Component({
    selector: 'app-liste-actions',
    standalone: true,
    templateUrl: './liste-actions.component.html',
    styleUrl: './liste-actions.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})
export class ListeActionsComponent 
{
    title:string = "Listes des actions" 
    pageSize:number = 7
    pageIndex:number = 1
    idUser!:number
    totalPages!:number
    totalResult!:number

    data_header_table:object = 
    {
      "adresse_ip":"Adresse IP",
      "date_heure":"Date et Heure",
      "nom":"Nom",
      "identifiant":"Identifiant",
      "user_agent":"User Agent",
      "action":"Action"
    }
    data_body_table: ListeActions[] = []
    isDataReady:boolean = false

    constructor
    (
      private listeActionsService:ListeActionsService,
      private sharedService: SharedService
    ) 
    {
      const id_user = this.sharedService.getIdUser()
      this.idUser = id_user
      this.createPagination(this.pageSize)
    }

    ngOnInit()
    {
      this.getDataListeActions()  
    }

    ngOnChanges(changes: SimpleChanges) 
    {
      if ('search' in changes) 
      {
        this.getDataListeActions()
      }
    }
  
    changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
    {  
      this.pageIndex = changeDisplay == false ? currentPage : 1 
      this.pageSize = pageSize
      this.getDataListeActions()
    }
  
    async createPagination(pageSize:number)
    {
      (await
        this.sharedService.getInfoPagination("liste_actions", this.idUser)).subscribe(data => {
        this.totalResult = data[0]?.total_result
        this.totalPages = Math.ceil(this.totalResult / pageSize)
      })
    }

    getDataListeActions(search:string="")
    {
      this.listeActionsService.getData( this.pageIndex ,this.pageSize, this.idUser, search).subscribe((data:ListeActions[]) => {
        this.data_body_table = data
        this.isDataReady = true
      })
    }
}