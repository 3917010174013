import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationService } from '../services/navigation/navigation.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SharedService } from '../services/shared/shared.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `
  <div class="dialog d-flex">
    <div class="col-4 bg-blue white bold dialog-title">RAPPEL ABONNEMENT</div>
    <div class="col-8 bg-white blue-dark-1 dialog-content-group">
        <div class="dialog-content">
            <p>Comme stipulé à l'article 6 de la Convention de Partenariat, nous restons dans l'attente de la mise à jour du nombre de vos abonnées en eau potable, eau brute, assainissement collectif, et assainissement non collectif à la date du <b>1er janvier {{date}}</b>.</p>
            <p>Ces données sont à renseigner par l'interlocuteur dédié à la "Gestion de la Convention", via l'onglet "Données abonnés".</p>
            <button class="rappel-abonnement" (click)="navigateTo()">
                {{ buttonText }}
                <img class="white-filter mx-1" src="/assets/icons/triangle.png" style="transform: rotate(-90deg)" height="15">
            </button>

            <button class="none start-timer" (click)="startTimer()"></button>
            
            <div class="mt-5 float-end">
                <small class="timer-text">Vous pourrez fermer cette fenêtre dans {{seconds}} seconde@if(seconds > 1){s}</small>
                <button type="button" class="bg-blue-dark-1 bold no-border px-3 py-1 mx-2 mb-2" (click)="closeDialog()" [disabled]="seconds > 1" [ngClass]="seconds >= 1 ? 'grey' : 'white' ">Fermer</button>
            </div>
        </div>
    </div>
  </div>
  `
})

export class RappelAbonnement
{
    date:number = new Date().getFullYear()
    seconds:number = 5
    interval!:ReturnType<typeof setTimeout>
    buttonText:string
    link:string

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private navigationService: NavigationService,
        private dialog: MatDialog,
        private sharedService: SharedService
    )
    {
        const userRights = this.sharedService.getUserRights()
        
        const saisie_donnees_abonnes = userRights.saisie_donnees_abonnes
        
        this.buttonText = saisie_donnees_abonnes ? 'Faire la mise à jour' : 'Voir vos interlocuteurs'
        this.link = saisie_donnees_abonnes ? 'donnees-abonnes' : 'interlocuteurs'
    }

    ngOnInit()
    {
        if (isPlatformBrowser(this.platformId))
        {
            (document.querySelector(".start-timer") as HTMLButtonElement).click()
        }
    }

    startTimer()
    {
        this.interval = setInterval(() => {
        if(this.seconds > 0) this.seconds--
        else return
        },1000)
    }

    navigateTo()
    {
        this.navigationService.navigateTo(this.link,[null]) 
        this.closeDialog()
    }

    closeDialog()
    {
        this.dialog.closeAll()
    }
}
