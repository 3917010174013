import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-searchbar',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './searchbar.component.html',
  styleUrl: './searchbar.component.css'
})
export class SearchbarComponent 
{
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>()
  searchForm:FormGroup = new FormGroup({
    searchValue: new FormControl("")
  })

  ngOnInit()
  {
    this.searchForm.valueChanges
      .pipe(debounceTime(500))
      .subscribe( data =>  {
        this.onSearch.emit(data.searchValue)
      })
  }
}
