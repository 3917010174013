<div class="d-flex me-4" *ngIf="totalResult > 7">
    <label>Affichage</label>
    &nbsp;

    <select class="select" (change)="changeItemsPerPage($event)" [value]="selectValue">
        @for (item of itemsOnPages; track $index) 
        {
            <option value="{{$index}}">{{item}} lignes / page </option>
        }
    </select>

</div>
