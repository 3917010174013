import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [CommonModule, MatPaginatorModule],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.css'
})
export class PaginationComponent 
{
  pageSize!:number
  totalPagesToShow:number = 3
  @Input() currentPage!:number
  @Input() totalPages!:number
  @Input() totalResult!:number
  @Output() currentPageChange: EventEmitter<number> = new EventEmitter<number>()
  @ViewChild(MatPaginator, {static:true}) paginator!: MatPaginator

  firstPage()
  {
    this.changePage(1)
  }

  lastPage()
  {
    this.changePage(this.totalPages)
  }

  previousPage()
  {
    if (this.currentPage > 1) 
    {
      this.changePage(this.currentPage - 1)
    }
  }

  nextPage()
  {
    if (this.currentPage < this.totalPages) 
    {
      this.changePage(this.currentPage + 1)
    }
  }

  changePage(pageNumber: number)
  {
    this.currentPage = pageNumber
    this.currentPageChange.emit(pageNumber)
  }

  getPages(): {pageNumber: number, isCurrentPage: boolean}[] 
  {
    // Retourne un tableau contenant les numéros de page à afficher dans la pagination
    let startPage = Math.max(1, this.currentPage - Math.floor(this.totalPagesToShow / 2))
    let endPage = Math.min(this.totalPages, startPage + this.totalPagesToShow - 1)

    // Ajustement si le nombre total de pagesToShow dépasse le nombre total de pages disponibles
    if (endPage - startPage + 1 < this.totalPagesToShow) 
    {
      startPage = Math.max(1, endPage - this.totalPagesToShow + 1)
    }

    return Array.from({ length: (endPage - startPage) + 1 }, (_, i) => {
      const pageNumber = startPage + i
      return {
        pageNumber: pageNumber,
        isCurrentPage: pageNumber === this.currentPage
      }
    })
  }
}
