import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ProfilService } from '../../services/profil/profil.service';
import { ListeActionsService } from '../../services/liste-actions/liste-actions.service';
import { ConnexionService } from '../../services/connexion/connexion.service';
import { LastConnexion } from '../../interfaces/ListeActions';
import { SharedService } from '../../services/shared/shared.service';
import { CommonModule } from '@angular/common';
import { contactInfo, service_eau } from '../../interfaces/ConnexionOut';

@Component({
    selector: 'app-header',
    standalone:  true,
    templateUrl: 'header.component.html',
    styleUrl: 'header.component.css',
    imports: [RouterLink, CommonModule]
})

export class HeaderComponent
{
    nom_espace:string = "ESPACE PARTENAIRES"
    civilite!:string
    nom!:string
    prenom!:string
    idUser!:number
    last_connexion!:string
    isDataReady:boolean = false
    service_list:service_eau[] = []

    constructor
    (
        private profilService: ProfilService,
        private listeActionsService: ListeActionsService,
        private connexionService: ConnexionService,
        private sharedService: SharedService,
        private router: Router
    )
    {
        const id_user = this.sharedService.getIdUser()
        this.idUser = id_user
    }

    ngOnInit()
    {
        this.getDataHeader()
        this.service_list = this.getElementsStartingWith("service")
    }

    getSessionStorageSize()
    {
        return sessionStorage.length
    }

    getElementsStartingWith(value:string) 
    {
        const elements:service_eau[] = []
        
        for (let i = 0; i < sessionStorage.length; i++) 
        {
            let key = sessionStorage.key(i)
            if (key && key?.startsWith(value)) 
            {
                elements.push(JSON.parse((sessionStorage.getItem(key) as string)))
            }
        }

        return elements
        .sort((a,b) => {
            if(a.service_eau.toLocaleLowerCase() < b.service_eau.toLocaleLowerCase())
            {
                return -1
            }

            if(a.service_eau.toLocaleLowerCase() > b.service_eau.toLocaleLowerCase())
            {
                return 1
            }

            return 0
        })
    }

    getDataHeader()
    {
        this.profilService.getProfil(this.idUser).subscribe(data => {
            this.civilite = data[0].civilite
            this.nom = data[0].nom
            this.prenom = data[0].prenom
            this.isDataReady = true
        })

        this.listeActionsService.getLastConnexion(this.idUser).subscribe((data:LastConnexion[]) => {
            this.last_connexion = data[0].last_connexion.toString()
        })
    }

    getServiceEau()
    {
        return this.sharedService.getService()
    }

    deconnexion()
    {
        this.connexionService.logout()
    }

    changeService(index:number)
    {   
        localStorage.setItem("current_service", index.toString())


        if(this.connexionService.isRefreshTokenExpired())
        {
            this.connexionService.logout()
        }
        else
        {
            this.sharedService.reFetchData()
            this.router.navigate(["/"])
        }
    }
}